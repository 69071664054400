import Routes from './routes'
import { CssBaseline, ThemeProvider } from '@mui/material'
import theme from './themes/custom-theme'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { useReducer } from 'react'
import GlobalState from './util/ctx'
import { TState } from './util/types'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
      staleTime: 0,
    },
  },
})

function App() {
  const [state, updateState] = useReducer(
    (state: TState, next: TState) => {
      return { ...state, ...next }
    },
    {
      role: 'guest',
      authCode: '',
    }
  )

  return (
    <GlobalState.Provider value={{ state, updateState }}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes />
        </ThemeProvider>
      </QueryClientProvider>
    </GlobalState.Provider>
  )
}

export default App
