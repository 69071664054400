import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import config from '../config/config'

const FaqsButton = () => {
  return (
    <Tooltip title="Hilfe">
      <Box>
        <IconButton
          href={config.helpUrl}
          className="faqsBtn"
        >
          <img alt="faqs" src="/images/faqs-button.svg" />
          <Typography variant="caption">Hilfe</Typography>
        </IconButton>
      </Box>
    </Tooltip>
  )
}

export default FaqsButton
