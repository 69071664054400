import {
  Alert,
  Autocomplete,
  Box,
  LinearProgress,
  TextField,
  Tooltip,
} from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useData, useUserFetch } from '../util/hooks'
import { TData, TUserInfoDTO } from '../util/types'

export type LocationValue = {
  label: string
  value: TData
}

export type LocationSelectProps = {
  locationId?: string | null
  defaultLocationId?: string | null
  error?: string | null
  onChange?: (newValue: TData | null) => void
}

const filterLocations = (user: TUserInfoDTO) => (location: TData) => {
  const groups = (user.groups || []).sort()
  let doesMatch = !!groups.find(group => {
    group = group.replace('/', '') // only first / is removed
    if (location.groups.includes(group)) {
      return true
    }
    // handle that we are Bund and location is for Bund/Sub1
    // if we would have been Bund/Sub1 the exact match would have been found above. Otherwise
    // if we are Bund/Sub2 we shouldnt match Bund/Sub1
    return !!location.groups.find(locGroup => {
      const parts = locGroup.split('/')
      if (parts.length <= 2) return false
      return parts[0] == group
    })
  })
  return doesMatch
}

const LocationSelect = ({
  locationId,
  defaultLocationId,
  error,
  onChange,
}: LocationSelectProps) => {
  const { isLoading, data, isError } = useData()
  const { user } = useUserFetch()
  const [tooltip, setTooltip] = useState<string>('')
  // dont use defaultLocationId directly as it might change
  // also locationId turns off selection which me may not want.
  const defaultCachedLocation = useMemo(() => defaultLocationId, [])

  const opts = useMemo(() => {
    if (!data || !user) return []
    return data.filter(filterLocations(user!)).map(o => {
      return {
        label: `${o.name} (${o.location})`,
        value: o,
      }
    })
  }, [data, user])

  const findLocationLabel = (locationId: string = ''): string => {
    if (!data) return ''
    const loc = data.find(
      o => o.location_id.toLowerCase() === locationId.toLowerCase()
    )
    if (loc) return `${loc.name} ${loc.location}`
    return ''
  }

  const handleValueChange = (
    event: React.SyntheticEvent,
    newValue: LocationValue | null
  ): void => {
    const { label, value } = newValue || {}
    setTooltip(label || '')
    if (onChange) onChange(value || null)
  }

  if (isError) {
    return (
      <Box sx={{ width: '100%' }} minWidth={'300px'}>
        <Alert severity="error">Problem with the database.</Alert>
      </Box>
    )
  }

  if (isLoading) {
    return (
      <Box sx={{ width: '100%' }} minWidth={'300px'}>
        <LinearProgress />
      </Box>
    )
  }

  if (locationId) {
    return (
      <Box sx={{ width: '100%' }} minWidth={'300px'}>
        <TextField
          multiline
          aria-readonly
          label="Gericht"
          value={findLocationLabel(locationId)}
          fullWidth
          InputProps={{
            readOnly: true,
          }}
        />
      </Box>
    )
  }

  return (
    <Box minWidth={'300px'}>
      <Tooltip title={tooltip} arrow placement={'top'}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          fullWidth
          loading={isLoading}
          options={opts}
          onChange={handleValueChange}
          defaultValue={opts.find(
            o => o.value.location_id === defaultCachedLocation
          )}
          getOptionLabel={option => option.label}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderInput={params => (
            <TextField
              {...params}
              error={!!error}
              label="Gericht"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
      </Tooltip>
    </Box>
  )
}

export default LocationSelect
