import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import {
  Box,
  Button,
  CardMedia,
  Fade,
  Link,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import { useData, useUserFetch } from '../util/hooks'
import Logo from '../components/Logo'
import AuthButton from '../components/AuthButton'
import {
  Navigate,
  NavigateFunction,
  useNavigate,
  useParams,
} from 'react-router-dom'
import BottomPanel from '../components/BottomPanel'
import config from '../config/config'
import CourtLogo from '../components/CourtLogo'
import { TData } from '../util/types'
import { roomNameForLinking } from '../util/urlUtil'
import HelpButton from '../components/HelpButton'

const FADE_MILLIS: number = 400

const DeepLinkLandingPage = () => {
  const navigate: NavigateFunction = useNavigate()

  const { location, room } = useParams()

  const { data } = useData()

  const [imgLoadComplete, setImgLoadComplete] = useState<boolean>(false)
  const [changing, setChanging] = useState<boolean>(false)
  const [image, setImage] = useState<string>('iStock-1062933252_bearb 1.png')
  const loadedImg = useRef<string>('')

  const loc = useMemo(() => {
    if (!data) return null
    if (!location) return null
    return data.find(
      o => o.location_id.toLowerCase() === location.toLowerCase()
    )
  }, [location, data])

  const convertAdditionalText = (loc: TData, room?: string) => {
    const parts = loc.additional_text.split('\n')
    const replaced = parts.map(o => {
      o = o.replace('{locname}', loc.name)
      o = o.replace('{room}', room || '')
      return o
    })
    const elements: ReactNode[] = []
    replaced.forEach((o, i) => {
      if (i > 0) elements.push(<br key={`br-${i}`} />)
      elements.push(o)
    })
    return elements
  }

  useEffect(() => {
    const bg: string = loc?.bg || 'iStock-1062933252_bearb 1.png'

    const t = setTimeout(() => {
      setChanging(false)
      setImage(bg)
      if (loadedImg.current !== bg) setImgLoadComplete(false)
    }, FADE_MILLIS)

    setChanging(true)
    return () => clearTimeout(t)
  }, [loc])

  let templateWelcome: React.ReactNode = (
    <>
      <Typography variant="h1">
        Willkommen im {loc?.name} beim Videoportal der Justiz!
      </Typography>
      <Typography variant="h2" pt={2}>
        Sie nehmen an der Videoverhandlung mit der <br></br>Konferenz-ID: {room} teil.
      </Typography>
    </>
  )

  const joinlicked = (): void => {
    console.log('join clicked')
    if (room) {
      navigate(`/jitsi/${location}/${roomNameForLinking(room)}`)
    }
  }

  // redirect to start page
  if (!location || !room || location == 'undefined' || room == 'undefined') {
    return <Navigate to="/" />
  }

  const videoTestParams = new URLSearchParams({
    location: location,
    room: room,
    court_name: loc?.name || '',
    contact_phone: loc?.contact_phone || '',
    contact_mail: loc?.contact_mail || '',
  })
  const videoTestUrl = `${config.videoTestUrl}/?${videoTestParams}`

  return (
    <>
      <Box className={'nav'}>
        <Logo />
        <CourtLogo location={location} />
        <Stack direction="row">
          <AuthButton />
          <HelpButton />
        </Stack>
      </Box>
      <Box className={'bgImage container'}>
        <Box style={{ height: '100%', width: '100%' }}>
          <Fade in={imgLoadComplete && !changing} timeout={FADE_MILLIS} appear>
            <CardMedia
              component="img"
              sx={{ height: '100%', width: '100%' }}
              image={`/images/locations/bg/${image}`}
              onLoad={() => {
                loadedImg.current = image
                setImgLoadComplete(true)
              }}
              alt={'bg'}
            />
          </Fade>

          <BottomPanel />
        </Box>

        <Paper
          className={'infoItems'}
          elevation={5}
          square
          style={{ opacity: '95%' }}
        >
          <Box className={'infoItems__welcome'}>{templateWelcome}</Box>

          <Box className={'infoItems__buttons-panel'}>
            <Box className={'item'}>
              Haben Sie überprüft, ob Ihre Kamera, Ihr Lautsprecher und Ihr Mikrofon angeschlossen und aktiv sind?
            </Box>

            <Box className={'buttons-wrapper'}>
              <Button
                className={'not-ready'}
                variant="outlined"
                href={videoTestUrl}
                fullWidth
              >
                Jetzt überprüfen
              </Button>

              <Button
                className={'ready'}
                variant="outlined"
                onClick={joinlicked}
                fullWidth
              >
                Ja, ich bin bereit
              </Button>
            </Box>
          </Box>

          <Box className={'infoItems__tip-panel'}>
            <Box className={'info'}>
              <Link underline="none" href={config.faqUrl}>
                Hier
              </Link>{' '}
              finden Sie Antworten auf die häufigsten Fragen zum Videoportal der Justiz.
            </Box>
          </Box>
        </Paper>
      </Box>
    </>
  )
}

export default DeepLinkLandingPage
