import { Box, Button, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { TMessageDTO } from '../util/types'
import config from '../config/config'

type ModeratorFreeTextProps = {
  forceText: string
  jwt?: string
  conferenceIdentifier: string
  onSend: (text: string) => void
}

const freeTextValidation: RegExp = /^[a-zA-Z0-9äöüÄÖÜß.:,!?\-\s]{1,255}$/

const ModeratorFreeTextEditor = ({
  onSend,
  forceText,
  conferenceIdentifier,
  jwt,
}: ModeratorFreeTextProps) => {
  const [text, setText] = useState<string>('')
  const [error, setError] = useState<string>('')

  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (payload: {
      location: string
      jwt: string
      message: TMessageDTO
    }) => {
      const url = `${config.backendURL}/api/messages/${conferenceIdentifier}`
      const options: RequestInit = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(payload.message),
      }
      return fetch(url, options)
    },
    onSuccess: () => {
      return queryClient.invalidateQueries(['messages', conferenceIdentifier])
    },
  })

  useEffect(() => {
    setText(forceText)
  }, [forceText])

  const handleSend = () => {
    if (error) return
    if (!jwt) return

    mutation.mutate({
      location: conferenceIdentifier,
      jwt,
      message: {
        message: text,
      },
    })

    if (onSend) onSend(text)
  }

  return (
    <Box className={'moderator-free-text-panel'}>
      <TextField
        id="outlined-multiline-flexible"
        label="Ausgewählte Nachricht"
        multiline
        fullWidth
        error={!!error}
        minRows={3}
        maxRows={3}
        value={text}
        onChange={e => {
          const value = e.target.value
          let err = ''
          if (value && !freeTextValidation.test(value)) {
            err = 'Contains invalid characters.'
          }
          setError(err)
          setText(e.target.value)
        }}
        inputProps={{ maxLength: 255 }}
      />

      <Button variant="contained" fullWidth onClick={handleSend}>
        Nachricht senden
      </Button>
    </Box>
  )
}

export default ModeratorFreeTextEditor
