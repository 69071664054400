import { Box, CardMedia, Typography } from '@mui/material'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { useData } from '../util/hooks'
import { useMemo } from 'react'

type FarewellMessageProps = {
  location_id?: string
  room?: string
}

const FarewellMessage = ({ location_id, room }: FarewellMessageProps) => {
  const navigate: NavigateFunction = useNavigate()
  const { data } = useData()
  const location = useMemo(
    () => data?.find(obj => obj.location_id === location_id)?.name,
    [location_id]
  )

  const linkClickHandler = (e: React.MouseEvent): void =>
    navigate(`/${location_id}/${room}`)

  return (
    <Box className="farewell-page">
      <Box className="shadow-box"></Box>
      <Box className="farewell-message">
        <Box className="text-container">
          <Box>
            <CardMedia
              component="img"
              sx={{ height: 109.957, width: 340, objectFit: 'contain' }}
              image={`/images/BMJ_Logo_Videoportal_rgb 1.png`}
              alt={'Logo'}
            />
            <Box className="text-alighn">
              <Typography className="title" variant="h2">
                Vielen Dank,
              </Typography>
              <Typography>
                dass Sie das Videoportal der Justiz im {location} genutzt haben.
              </Typography>
              <br />
              <Typography>
                Zur Startseite Ihrer Verhandlung kommen Sie{' '}
                <a href="#" onClick={linkClickHandler}>
                  hier
                </a>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default FarewellMessage
