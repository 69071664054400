import { Autocomplete, Box, LinearProgress, TextField } from '@mui/material'
import React, { useMemo } from 'react'
import { usePredefinedMessages } from '../util/hooks'

type ModeratorPredefinedTextProps = {
  onChange?: (text: string) => void
}

type Selector = {
  label: string
  value: string
}

const ModeratorPredefinedTextEditor = ({
  onChange,
}: ModeratorPredefinedTextProps) => {
  const { data, isLoading } = usePredefinedMessages()

  const opts = useMemo(() => {
    if (!data) return []
    return data.map(o => {
      return {
        label: `${o.message}`,
        value: o.message,
      }
    })
  }, [data])

  const handleValueChange = (
    event: React.SyntheticEvent,
    newValue: Selector | null
  ): void => {
    if (onChange) onChange(newValue?.value || '')
  }

  if (isLoading) {
    return (
      <Box sx={{ width: '100%' }} minWidth={'300px'}>
        <LinearProgress />
      </Box>
    )
  }

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      fullWidth
      loading={isLoading}
      options={opts}
      onChange={handleValueChange}
      getOptionLabel={option => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderInput={params => (
        <TextField
          {...params}
          label="Wählen Sie Ihre Nachricht aus"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  )
}

export default ModeratorPredefinedTextEditor
