import MinimalLayout from '../layout/MinimalLayout'
import { Navigate, RouteObject } from 'react-router-dom'
import ProtectedRoute from './ProtectedRoute'
import DeepLinkLandingPage from '../views/DeepLinkLandingPage'
import NewJitsi from '../views/NewJitsi'

const MainRoutes: RouteObject = {
  path: '/',
  element: <ProtectedRoute />,
  children: [
    {
      path: '/',
      element: <MinimalLayout />,
      children: [
        { path: '/:location/:room', element: <DeepLinkLandingPage /> },
        { path: '/jitsi/:location/:room', element: <NewJitsi /> },
      ],
    },
    { path: '*', element: <Navigate to="/" replace /> },
  ],
}

export default MainRoutes
