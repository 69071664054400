import React, { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  CardMedia,
  FormControl,
  InputLabel,
  Link,
  OutlinedInput,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import '@fontsource/open-sans/400.css'
import '@fontsource/open-sans/600.css'
import '@fontsource/open-sans/700.css'
import LocationSelect from '../components/LocationSelect'
import { useNavigate } from 'react-router-dom'
import AuthButton from '../components/AuthButton'
import Logo from '../components/Logo'
import BottomPanel from '../components/BottomPanel'
import config from '../config/config'
import { roomNameForLinking } from '../util/urlUtil'
import { useData, useUserFetch } from '../util/hooks'
import HelpButton from '../components/HelpButton'

const dumbestCacheEver = {
  loc: '',
  room: '',
}

const ConferenceSelectorPage = () => {
  const [localLocation, setLocalLocation] = useState<string>(
    dumbestCacheEver.loc
  )
  const [localRoom, setLocalRoom] = useState<string>(dumbestCacheEver.room)
  // setup caching on page leave
  useEffect(() => {
    return () => {
      dumbestCacheEver.loc = localLocation
      dumbestCacheEver.room = localRoom
    }
  }, [localLocation, localRoom])

  const navigate = useNavigate()
  const [errorRoom, setErrorRoom] = useState<string>('')
  const [errorLocation, setErrorLocation] = useState<string>('')

  const roomNameRegexp: RegExp = /^[a-zA-Z0-9äöüÄÖÜß()/\-\s]{5,50}$/

  const {
    user,
    isLoading: userLoading,
    isError: userLoadingError,
  } = useUserFetch()
  useEffect(() => {
    if (userLoadingError || (!user && !userLoading)) {
      window.location.href = config.unauthedLandingUrl
    }
  }, [user, userLoading, userLoadingError])

  const readyClick = () => {
    console.log('join clicked')

    var error = false

    if (!localLocation) {
      error = true
      setErrorLocation('Required')
    }

    if (!localRoom) {
      error = true
      setErrorRoom('Required')
    }

    if (error) return

    setErrorRoom('')
    setErrorLocation('')

    navigate(`/${localLocation}/${roomNameForLinking(localRoom)}`)
  }

  const location = localLocation != '' ? localLocation : 'MEETINGS'
  const { data } = useData()
  const loc = useMemo(() => {
    if (!data) return null
    if (!location) return null
    return data.find(
      o => o.location_id.toLowerCase() === location.toLowerCase()
    )
  }, [location, data])

  const videoTestParams = new URLSearchParams({
    court_name: loc?.name || '',
    contact_phone: loc?.contact_phone || '',
    contact_mail: loc?.contact_mail || '',
    ...(localLocation != '' ? { location, room: localRoom } : {}),
  })
  const videoTestUrl = `${config.videoTestUrl}/?${videoTestParams}`

  return (
    <>
      <Box className={'nav'}>
        <Logo />
        <Stack direction="row">
          <AuthButton />
          <HelpButton />
        </Stack>
      </Box>
      <Box className={'bgImage container'}>
        <CardMedia
          sx={{ height: '100%', width: '100%' }}
          image="/images/iStock-1062933252_bearb 1.png"
        >
          <BottomPanel />
        </CardMedia>
        <Paper className={'infoItems'} elevation={5} square>
          <Box className={'infoItems__welcome'}>
            <Typography variant="h1">
              Willkommen beim Videoportal der Justiz!
            </Typography>
            <Typography variant="h2" pt={2}>
              Hier können Sie einen Link für eine Videokonferenz generieren,
              wenn Ihr Fachverfahren dafür keine Möglichkeit bietet.{' '}
            </Typography>
          </Box>
          <Box className={'infoItems__selectors-grid'}>
            <LocationSelect
              error={errorLocation}
              defaultLocationId={localLocation}
              onChange={v => setLocalLocation(v?.location_id || '')}
            />
            <Box className={'item'}>
              Bitte hier den Namen Ihres Gerichts bzw. Ihrer Justizbehörde
              auswählen.
            </Box>
            <FormControl error={!!errorRoom} fullWidth>
              <InputLabel htmlFor="LocationSelectRoom-roomid">
                Konferenz-ID
              </InputLabel>
              <OutlinedInput
                id="LocationSelectRoom-roomid"
                aria-describedby="component-error-text"
                inputProps={{ maxLength: 50 }}
                label={'Aktenzeichen'}
                readOnly={false}
                autoComplete="off"
                defaultValue={localRoom}
                placeholder={'Hier Aktenzeichen eintragen'}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const value = event.target.value.trim()
                  let err = ''
                  if (value && !roomNameRegexp.test(value)) {
                    err = 'Contains invalid characters.'
                  }
                  if (value && value.length < 5) {
                    err = 'Too short.'
                  }
                  setErrorRoom(err)
                  setLocalRoom(err ? '' : value)
                }}
              />
            </FormControl>
            <Box className={'item'}>
              Als Konferenz-ID geben Sie bitte eine 8-stellige Kombination aus
              Buchstaben und Ziffern ein. Sonderzeichen sind nicht erlaubt.
            </Box>
          </Box>
          <Box className={'infoItems__buttons-panel'}>
            <Box className={'item'}>
              Hier können Sie jederzeit überprüfen, ob Ihre Kamera, Ihr Mikrofon
              und Ihr Lautsprecher funktionieren.
            </Box>
            <Box className={'buttons-wrapper'}>
              <Button
                className={'not-ready'}
                variant="outlined"
                href={videoTestUrl}
                fullWidth
              >
                Jetzt überprüfen
              </Button>
              <Button
                className={'ready'}
                variant="outlined"
                onClick={readyClick}
                fullWidth
              >
                Konferenz starten/beitreten
              </Button>
            </Box>
          </Box>
          <Box className={'infoItems__tip-panel'}>
            <Box className={'info'}>
              <Link underline="none" href={config.faqUrl}>
                Hier
              </Link>{' '}
              finden Sie Antworten auf die häufigsten Fragen zum Videoportal der
              Justiz.
            </Box>
          </Box>
        </Paper>
      </Box>
    </>
  )
}

export default ConferenceSelectorPage
