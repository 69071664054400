import React from 'react'
import { createRoot } from 'react-dom/client'

import './assets/scss/style.scss'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import config from './config/config'

const container = document.getElementById('root')
const root = createRoot(container!) // createRoot(container!) if you use TypeScript

console.log(config.version)

root.render(
  <React.StrictMode>
    <BrowserRouter basename={'/'}>
      <App />
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
