import { RouteObject } from 'react-router-dom'
import MinimalLayout from '../layout/MinimalLayout'
import ConferenceSelectorPage from '../views/ConferenceSelectorPage'
import ProtectedRoute from './ProtectedRoute'

const LandingRoute: RouteObject = {
  path: '/',
  element: <ProtectedRoute />,
  children: [
    {
      path: '/',
      element: <MinimalLayout />,
      children: [{ path: '/', element: <ConferenceSelectorPage /> }],
    },
  ],
}

export default LandingRoute
