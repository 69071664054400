import { createTheme } from '@mui/material/styles'
import colors from '../assets/scss/_style.module.scss'

const theme = createTheme({
  palette: {
    background: {
      default: colors.background,
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
      fontSize: '0.75rem',
    },
    h5: {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 700,
    },
    h1: {
      fontSize: '2.125rem',
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: '0.875rem',
      fontWeight: 500,
      color: colors.grey900,
    },
  },
})

export default theme
